<template>
  <div class="userDetails">
    <div class="header">
      <div>
        <a-button @click="goBack" type="link" style="margin-right: 50px"
          >返回列表</a-button
        >
        <span style="margin-right: 50px">{{ basicInfo.region }}</span>
        <span style="margin-right: 50px">{{ basicInfo.type }}</span>
        <span>{{ basicInfo.name }}</span>
      </div>
      <div>
        <a-button @click="visible = true">立即疏散</a-button>
      </div>
    </div>
    <a-tabs :tabBarGutter="100" size="large" default-active-key="1">
      <a-tab-pane key="1" tab="基本信息">
        <basic-info :basic-info="basicInfo" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="ACL规则">
        <acl-rules :acl-rules-info="aclRulesInfo" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="用户配置信息">
        <configuration-info :configuration-info="configurationInfo" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="流量详情">
        <flow-details :flow-details="flowDetails" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="限速策略">
        <qos-policies :qos-policies="qosPolicies" />
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      :closable="false"
      title="选择疏散目标节点"
    >
      <template slot="footer">
        <a-button key="back" @click="visible = false"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          确定
        </a-button>
      </template>
      <a-radio-group
        name="radioGroup"
        v-model="evacuateNode"
        :default-value="1"
      >
        <a-radio :value="1"> 疏散到任意节点 </a-radio>
        <a-radio :value="2"> 疏散到指定节点 </a-radio>
        <a-select
          v-show="evacuateNode === 2"
          style="width: 120px"
          placeholder="Select a person"
          v-model="assignNode"
        >
          <a-select-option
            v-for="item in nodeListInfo"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-radio-group>
    </a-modal>
  </div>
</template>

<script>
import basicInfo from "./components/basicInfo.vue";
import aclRules from "./components/aclRules.vue";
import configurationInfo from "./components/configurationInfo.vue";
import flowDetails from "./components/flowDetails.vue";
import qosPolicies from "./components/qosPolicies.vue";

import { getNodeDetails, getNodeList, evacuateNode } from "../../server/api";
export default {
  name: "NodeDetails",
  components: {
    basicInfo,
    aclRules,
    configurationInfo,
    flowDetails,
    qosPolicies,
  },
  data() {
    return {
      visible: false,
      loading: false,
      basicInfo: {},
      aclRulesInfo: [],
      configurationInfo: {},
      flowDetails: {},
      qosPolicies: {},
      evacuateNode: 1,
      assignNode: "",
      nodeListInfo: [],
    };
  },
  created() {
    // 获取节点详情
    getNodeDetails(this.$route.query.id).then((res) => {
      this.basicInfo = {
        region: res.node.region,
        type: res.node.type,
        name: res.node.name,
        node_ip: res.node.node_ip,
        id: res.node.id,
      };
      this.aclRulesInfo = res.node.acl_rules;
      this.configurationInfo = res.node.user_infos;
      this.flowDetails = res.node.traffic_details;
      this.qosPolicies = res.node.qos_policies;
    });
    this.getNodeListFunc();
  },
  methods: {
    //获取所有节点列表
    getNodeListFunc() {
      getNodeList({}).then((res) => {
        this.nodeListInfo = res.nodes;
      });
    },
    //返回列表页
    goBack() {
      this.$router.go(-1);
    },

    //弹窗确定按钮
    handleOk() {
      this.loading = true;
      //疏散到任意节点
      if (this.evacuateNode === 1) {
        let dataObj = {
          source_node: this.basicInfo.id,
          destination_node: "",
        };
        this.evacuateNodeFunc(dataObj);
      } else {
        //疏散到指定节点
        if (this.assignNode === "") {
          this.$message.error("疏散到指定节点请选择节点！");
          return;
        } else {
          let dataObj = {
            source_node: this.basicInfo.id,
            destination_node: this.assignNode,
          };
          this.evacuateNodeFunc(dataObj);
        }
      }
    },
    // 疏散节点
    evacuateNodeFunc(data) {
      evacuateNode(data)
        .then((res) => {
          console.log(res);
          this.$message.success("已疏散节点！");
          this.visible = false;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$message.error("疏散失败，请联系管理员！");
        });
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
</style>
