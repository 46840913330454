<template>
  <div class="basicInfo">
    {{ flowDetails }}
  </div>
</template>

<script>
export default {
  name: "FlowDetails",
  props: ["flowDetails"],
};
</script>
<style scoped></style>
