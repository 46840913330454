<template>
  <div class="AclRules">
    <a-list :data-source="aclRulesInfo">
      <a-list-item slot="renderItem" slot-scope="item">
          <span>{{ item.pkts }}</span>
          <span>{{ item.bytes }}</span>
          <span>{{ item.target }}</span>
          <span>{{ item.prot }}</span>
          <span>{{ item.source }}</span>
          <span>{{ item.destination }}</span>
          <span>{{ item.port }}</span>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
export default {
  name: "AclRules",
  props: ["aclRulesInfo"],
  created() {
    console.log(this.aclRulesInfo,'aclRulesInfo')
  },
};
</script>
<style scoped></style>
