<template>
  <div class="basicInfo">
    {{ qosPolicies }}
  </div>
</template>

<script>
export default {
  name: "QosPolicies",
  props: ["qosPolicies"],
};
</script>
<style scoped></style>
