<template>
  <div class="basicInfo">
    <a-form
      :form="basicInfo"
      :label-col="{ span: 1 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-form-item label="节点名称">
        <span>{{ basicInfo.name }}</span>
      </a-form-item>
      <a-form-item label="节点类型">
        <span>{{ basicInfo.type }}</span>
      </a-form-item>
      <a-form-item label="节点区域">
        <span>{{ basicInfo.region }}</span>
      </a-form-item>
      <a-form-item label="节点IP">
        <span>{{ basicInfo.node_ip }}</span>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "BasicInfo",
  props: ["basicInfo"],
};
</script>
<style scoped></style>
