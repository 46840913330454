<template>
  <div class="basicInfo">
    {{ configurationInfo }}
  </div>
</template>

<script>
export default {
  name: "ConfigurationInfo",
  props: ["configurationInfo"],
};
</script>
<style scoped></style>
